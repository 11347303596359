/* Ensure everything respects the viewport size */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

canvas {
  display: block;
}

/* ---- particles.js container ---- */

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.light-mode #particles-js {
  background-color: rgba(0, 0, 0, 0.2);
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: white;
  padding: 8px;
  z-index: 100;
}

.skip-link:focus {
  top: 0;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 80vh;
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;
  z-index: 1;
}

.profile-container {
  margin: 5% 0;
}

@keyframes segmented-rainbow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.rainbow-text {
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
  background-size: 50% 50%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: segmented-rainbow 10s ease infinite;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.profile-header {
  display: flex;
  align-items: center;
  z-index: 1;
}

.profile-header h1 {
  margin-left: 10px;
}

.profile-picture-small {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.profile-picture-large {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 20px auto 20px auto;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

main h2 {
  font-size: 2.5rem;
  margin: 0;
}

main p {
  font-size: 1.25rem;
  color: #aaa;
}

.toggle-switch {
  position: relative;
  width: 45px;
  height: 45px;
  background: #e2e0e0;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px;
  transition: background .5s;
  z-index: 1;
}

.toggle-knob {
  width: 35px;
  height: 35px;
  background-image: url('./assets/icons/on.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.toggle-knob.dark {
  background-image: url('./assets/icons/off.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.dark-mode .toggle-switch {
  background: #434242;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1A1A1A;
  padding: 20px;
  margin-top: auto;
}

header, footer {
  background-color: rgba(204, 198, 198, 0.8); /* for light mode */
  position: relative;
  z-index: 2;
}

footer p {
  margin: 0;
}

.dark-mode header, .dark-mode footer {
  background-color: rgba(27, 27, 27, 0.6);
  color: #EFEFEF;
}

.social-icons a {
  color: #aaa;
  margin: 0 10px;
  font-size: 1.25rem;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #fff;
}

.dark-mode {
  background-color: #000;
  color: #fff;
}

.light-mode {
  background-color: #fff;
  color: #000;
}

.light-mode main p,
.light-mode .social-icons a {
  color: #555;
}

button.scroll-to-top {
  display: inline-block;
  background-color: #fff;
  color: black;
  padding: 12px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
  z-index: 2;
}

.light-mode button.scroll-to-top {
  background-color: #000;
  color: #fff;
}

button.scroll-to-top:hover {
  opacity: 1;
}

.resume-container {
  display: inline-block;
  position: relative;
}

.resume-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 0.5%;
}

.resume-button:hover {
  background-color: #444;
}

.resume-dropdown {
  position: absolute;
  background-color: #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
  min-width: 120px;
  margin-top: 0.1%;
}

.resume-option {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5%;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.resume-option:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.resume-option:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.resume-option:hover {
  background-color: #444;
}

.dark-mode .resume-button:hover,
.dark-mode .resume-option:hover {
  background-color: #4a4d67;
}

/* Timeline styles */
@keyframes circling-glow {

  0%,
  100% {
    box-shadow: 0 5px 0px 2px rgba(255, 0, 0, 0.6);
  }

  25% {
    box-shadow: -5px 0 0px 2px rgba(77, 172, 77, 0.6);
  }

  50% {
    box-shadow: 0 -5px 0px 2px rgba(0, 0, 255, 0.6);
  }

  75% {
    box-shadow: 5px 0 0px 2px rgba(254, 254, 124, 0.6);
  }
}

.section-header {
  margin-top: 10%;
  margin-bottom: 5%;
}

.timeline-container {
  position: relative;
  width: 100%;
  margin-bottom: 5%;
  z-index: 1;
}

.timeline-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background: #8f9797;
  z-index: 1;
  transform: translateX(-50%);
  overflow: hidden;
}

.fluid-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000000;
  transition: height 0.3s ease-out;
}

/* Timeline items with proper layout */
.timeline-item {
  background: transparent;
  position: relative;
  margin: 10px 0;
  width: 50%;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-out;
  border-radius: 25px;
}

.timeline-item.active {
  opacity: 1;
  transform: translateY(0);
}

.timeline-item.left {
  left: 0;
  border-radius: 25px;
}

.timeline-item.right {
  left: 50%;
  border-radius: 25px;
}

.timeline-item .content {
  background-color: rgba(204, 198, 198, 0.9);
  padding: 15px;
  position: relative;
  box-shadow: 0 4px 8px rgba(144, 80, 80, 0.5);
  overflow-wrap: break-word;
  color: #000;
  border-radius: 25px;
}

.timeline-item:hover {
  transform: scale3d(1.0055) translateZ(10px);
  animation: circling-glow 2s linear infinite;
}

.timeline-item.left .content {
  transform: translateX(-100%);
}

.timeline-item.right .content {
  transform: translateX(100%);
}

.timeline-item.active.left .content,
.timeline-item.active.right .content {
  transform: translateX(0);
}

/* Dark mode styles for timeline */
.dark-mode .section-header {
  color: #fff;
}

.dark-mode .timeline-line {
  background: #3a3a3a;
}

.dark-mode .fluid-fill {
  background: #c8c7c7;
}

.dark-mode .timeline-item .content {
  background-color: rgba(27, 27, 27, 0.9);
  color: #fff;
  box-shadow: 0 4px 8px rgba(151, 92, 92, 0.3);
}

.dark-mode .timeline-item .content:hover {
  animation: circling-glow 2s linear infinite;
  transform: scale3d(1.0055) translateZ(10px);
  z-index: 2;
}

/* Skills section */

.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
  max-width: 1400px;
  margin: 5px auto;
  font-size: 0.9rem;
}

.skill-group {
  position: relative;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-skill {
  width: 120px;
  height: 120px;
  background-color: rgba(20, 71, 104, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;
  transition: transform 0.3s ease;
}

.child-skill {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-color: rgba(45, 85, 87, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  transform: var(--initial-transform);
  transition: transform 0.4s ease;
  opacity: 0;
  z-index: 1;
}

.skill-group:hover .child-skill {
  opacity: 1;
  transform: var(--hover-transform);
  transition: transform 0.4s ease, opacity 0.3s ease;
}

/* Container to position the ring and circle independently */
.ring-container {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Circle element */
.parent-skill {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1; /* Place above the ring */
}

/* Ring with animated rotation */
.skill-ring {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.skill-ring::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet,
    red
  );
  -webkit-mask: 
    radial-gradient(circle, #000 65%, transparent 67%);
  mask: 
    radial-gradient(circle, #000 65%, transparent 67%);
  animation: spin 3s linear infinite;
  box-sizing: border-box;
}

.skill-group:hover .skill-ring {
  opacity: 1;
}

/* Spinning animation for the ring */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive rules */
@media screen and (max-width: 768px) {

  .profile-container {
    margin: 30% 0;
  }

  .section-header {
    margin-top: 20%;
  }

  .timeline-item {
    width: 100%;
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
    transform: none;
  }

  .timeline-item.left .content {
    transform: none;
  }

  .timeline-item.right.active .content,
  .timeline-line,
  .highlight-line {
    left: 0;
  }
}

@media screen and (max-width: 400px) {
  .profile-container {
    margin: 5% 0;
  }

  .section-header {
    margin-top: 35%;
  }
}

@media screen and (max-width: 350px) {
  .profile-container {
    min-height: 85vh;
  }
}